import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    deanCrowell: Core.ImageParagraphWrapImageProps;
    andrewMertha: Core.ImageAttributes;
    andreaPresbitero: Core.ImageAttributes;
    filippoTaddei: Core.ImageAttributes;
    johnHarper: Core.ImageAttributes;
    johnHarperMargin: Core.ImageAttributes;
    sitIn: Core.ImageAttributes;
    leviNapolitano: Core.ImageAttributes;
    conspiracy: Core.ImageAttributes;
    exodus: Core.ImageAttributes;
    disparity: Core.ImageAttributes;
    debt: Core.ImageAttributes;
    africa: Core.ImageAttributes;
    marta: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    andrewMertha,
    deanCrowell,
    andreaPresbitero,
    filippoTaddei,
    johnHarper,
    johnHarperMargin,
    sitIn,
    leviNapolitano,
    conspiracy,
    exodus,
    disparity,
    debt,
    africa,
    marta
  } = props.data;

  return (
    <Layout departmentSlug="dispatch" title="Dispatch">
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.DepartmentLabel>Dispatch</Core.DepartmentLabel>
          <Core.Flag>SAIS News</Core.Flag>
        </Core.ContentSection>
        <Core.ContentSection id="cromwell-committee">
          <Core.PrimaryHeading>
            Crowell Committee Brings New Eyes to Advancing Diversity
          </Core.PrimaryHeading>
          <Core.ImageParagraphWrap image={deanCrowell}>
            When worldwide protests erupted in response to George Floyd’s
            killing by police, many people questioned the state of race
            relations and the commitment to advancing diversity at their own
            institutions, asking themselves: What are we doing to counter the
            inequities laid bare?
          </Core.ImageParagraphWrap>
          <p>
            In response to queries from students, faculty, staff, and alumni,
            SAIS Dean <Core.BoldText>Eliot Cohen</Core.BoldText> immediately
            created the Crowell Committee. It is named for SAIS’ first African
            American senior administrator, George Crowell, the beloved associate
            dean of students who died in 1995.
          </p>
          <p>
            “I ask that the committee act in his spirit,” the dean wrote to its
            members on July 2. And he directed that that it act quickly — by the
            first week of September — “to make concrete, actionable
            recommendations for elements we should consider specific to
            advancing diversity, equity, and inclusion efforts at SAIS in four
            core areas:
          </p>
          <ul>
            <li>Recruitment of faculty and students</li>
            <li>Advising and mentoring</li>
            <li>Programming and community engagement</li>
            <li>Development and fundraising</li>
          </ul>
          <p>
            <Core.BoldText>Miji Bell</Core.BoldText>, director of communications
            and media relations at SAIS, was appointed committee chair. “There
            have been ongoing diversity and inclusion initiatives at SAIS, but
            the historic moment we collectively find ourselves in adds a
            different level of urgency to our efforts,” she says. “Dean Cohen
            wanted us to come at it with new eyes. The members of the committee
            include faculty, alumni, staff, and students. They are all amazing
            people — all deeply committed to creating a plan for very specific
            reforms, some long term, but also things we can implement
            immediately.”
          </p>
          <p>
            Kendall Simmonds ’05, a senior vice president in Wells Fargo’s
            commercial banking division who serves on the committee, says he was
            eager “to share, not only my perspective as an African-American male
            who progressed through SAIS but also as someone who could help
            students bridge the transition to their professional careers.
          </p>
          <p>
            “Dean Cohen said he believes the events happening today affect us
            all, and we can’t be silent, and I think it’s quite a statement when
            the leadership of a school initiates change because then it’s not a
            fleeting event but something the institution holds true to its
            core.”
          </p>
          <p>
            Min Chang, who is working toward her PhD in international affairs as
            a student at SAIS and has spent 32 years in the business world,
            amplifies Simmonds’ point: “I’ve learned that if you want to change
            a company’s behavior, you can’t just form, say, a diversity and
            inclusion department. You won’t succeed unless you embed it in
            everything you do.”
          </p>
          <p>
            Committee members agree that the pain of the present moment has
            created a unique opportunity.
          </p>
          <p>
            “I work a lot on post-conflict countries, which is when societies
            tend to be most open to change,” says{' '}
            <Core.BoldText>Dan Honig</Core.BoldText>, assistant professor of
            International Development at SAIS. “But as someone who has spent a
            lot of time looking at other institutions and who gets to be part of
            decision-making processes, I found myself surprised at how little
            I’d thought about parallel issues in my own life. And I think that’s
            a feeling a lot of folks are having now. Focusing everyone’s
            attention on this issue at the same time is an amazing opportunity,
            so I am very hopeful for change and that our work as a committee
            will be part of it.”
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sais.jhu.edu/crowell-committee"
            >
              Read more on the Crowell Committee
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Mertha Assumes Role of Vice Dean for Faculty Affairs
          </Core.PrimaryHeading>
          <Core.FullWidthImage image={andrewMertha} alt="Andrew Merta" />
          <p>
            On July 1, <Core.BoldText>Andrew Mertha</Core.BoldText>, the George
            and Sadie Hyman Professor of China Studies, assumed the role of vice
            dean for faculty affairs and international research cooperation. He
            succeeds <Core.BoldText>Kent Calder</Core.BoldText>, who has
            returned to his duties as Edwin O. Reischauer Professor of East
            Asian Studies and director of the Edwin O. Reischauer Center for
            East Asian Studies.
          </p>
          <p>
            Mertha, who is also director of the China Program and of SAIS China,
            came to SAIS in 2018. “Since his arrival at SAIS, Andy Mertha has
            proven himself to be an empathetic and versatile leader,” notes SAIS
            Dean <Core.BoldText>Eliot Cohen</Core.BoldText>. “His dynamism and
            creativity will be essential in helping to shape the transformation
            of our curriculum, organization, and governance structures now
            underway at SAIS.”
          </p>
          <p>
            Mertha, who earned his BA and PhD from the University of Michigan,
            served as an assistant professor of political science at Washington
            University in St. Louis and then spent a decade at Cornell
            University as a professor of government before coming to SAIS. The
            author of three books, Mertha speaks five languages (including
            Chinese and Khmer) and is a leading scholar of Chinese and Cambodian
            politics whose research on political institutions, foreign policy
            processes, and the exercise of power is widely respected and quoted
            in media outlets ranging from The Wall Street Journal to the
            International Herald Tribune to The Cambodia Daily.
          </p>
          <p>
            He has provided public testimony for the U.S.-China Economic and
            Security Review Commission, briefed the Congressional-Executive
            Commission on China, and has accompanied a U.S. congressional staff
            delegation to Beijing, Xinjiang, and Shanghai to discuss issues of
            terrorism and narcotics trafficking. Among other involvements,
            Mertha serves as vice president of the Center for Khmer Studies.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>New Face on the Faculty</Core.PrimaryHeading>
          <Core.FullWidthImage
            image={andreaPresbitero}
            alt="Andrea Presbitero"
          />
          <p>
            <Core.BoldText>Andrea Presbitero</Core.BoldText> joined the SAIS
            Europe resident faculty team in September as the Vera and Stefano
            Zamagni Associate Professor of Economic Development. Presbitero
            comes to Johns Hopkins SAIS from the International Monetary Fund,
            where he was the senior economist of the Research Department’s
            Macro-Financial Division. Before joining the IMF, he was assistant
            professor at the Università Politecnica delle Marche. He is an
            applied economist whose recent work focuses on financial inclusion
            in Africa. His research interests cover financial intermediation,
            development finance, monetary policy and sovereign debt. He is also
            associate editor of Economia (LACEA) and the Journal of Financial
            Stability.
          </p>
          <p>
            Presbitero received his PhD from Università Politecnica delle Marche
            and an MA in development economics from the University of Sussex. He
            will begin teaching courses at SAIS Europe in the spring, covering
            Corporate Finance and Macroeconomic Risk and International Finance
            to start.
          </p>
          <p>
            “I am excited to be back in Italy and back to academia,” he says.
            “Given my research and policy experience, I am so pleased to join an
            organization with such a diverse faculty and student body, and such
            a strong emphasis on policymaking.”
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>Taddei Steps Down</Core.PrimaryHeading>
          <Core.FullWidthImage image={filippoTaddei} alt="Filippo Taddei" />
          <p>
            <Core.BoldText>Filippo Taddei</Core.BoldText>, associate professor
            of the Practice of International Economics, will be leaving his
            position at SAIS Europe to become executive director in global
            investment research at Goldman Sachs in London. Taddei has been an
            essential part of the SAIS Europe faculty team over the past decade,
            both as a professor and as the founding academic director of the
            Master of Arts in Global Risk.
          </p>
          <p>
            He came to Johns Hopkins SAIS with extensive experience as a
            policymaker and senior adviser, with a specific focus on labor and
            credit markets. From 2013 to 2017, he served as economic and labor
            affairs spokesperson for the ruling Partito Democratico (Democratic
            Party), reporting directly to the Italian prime minister. In this
            capacity, Taddei was one of the main architects of the Italian labor
            market reform in 2014–15. He earned his PhD in economics with
            distinction from Columbia University and his laurea cum laude from
            the University of Bologna.
          </p>
          <p>
            Reflecting on his time at Johns Hopkins SAIS, Taddei observes: “I
            can hardly envision my future without SAIS. I learned so much from
            our community in the last eight years. The engagement of colleagues
            and the passion of students is a truly unique combination.”
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Harper Retires from Full-Time Role
          </Core.PrimaryHeading>
          <Core.FullWidthImage image={johnHarper} alt="John Harper" />
        </Core.ContentSection>

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={johnHarperMargin}
              alt="John Harper"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <p>
            <Core.BoldText>John Harper</Core.BoldText>, a professor based in
            Bologna for almost 40 years, retired from his position as the
            Kenneth H. Keller Professor of American Foreign Policy at the end of
            June. Although he is stepping down from his position as a full-time
            resident faculty member, he will continue to teach at SAIS Europe as
            an adjunct professor.
          </p>
          <p>
            After finishing his undergraduate work at Haverford College, Harper
            began his graduate career at Johns Hopkins SAIS (B’76, DC’77). He
            learned about the school from one of his professors and mentors,
            Patrick McCarthy, who also joined the Johns Hopkins SAIS community
            soon after and was an important colleague, friend, and mentor. After
            earning his master’s degree, Harper enrolled in the Johns Hopkins
            SAIS PhD program in Washington, D.C., writing his dissertation under
            SAIS Emeritus Professor, David P. Calleo. In 1981, upon being
            awarded his degree, Harper returned to the Bologna Center as a
            professor. He has been an essential part of the Bologna faculty ever
            since.
          </p>
          <p>
            “My career in Bologna began by chance when they fired a junior
            person and told me that if I finished my [SAIS] dissertation on the
            U.S. and the postwar reconstruction of Italy, there was a job
            starting in the second semester of 1980–81. And thus, as many have
            heard me say, ‘Ronald Reagan came in one end of Washington, and I
            went out the other,’” he says.
          </p>
          <p>
            Known as a passionate teacher committed to his students, to which a
            generation of Bologna Center students in his American Foreign Policy
            courses can attest, he is also widely known at the Bologna Center
            for his very popular debate class on the choices faced by U.S.
            foreign policy experts.
          </p>
          <p>
            In addition to the debate course and a lecture-style course on
            American foreign policy, Harper also created a small case study
            seminar in which students combined independent research, one-on-one
            tutoring sessions, small-group discussions, and a short
            paper-writing course in which students wrote nine short papers
            throughout the semester on historical aspects of American foreign
            policy.
          </p>
          <p>
            “Anyone who knows me will tell you that the main reason I’ve stayed
            in Bologna so long is the local wine called Pignoletto,” he jokes.
          </p>
          <p>
            All joking aside, Harper’s long tenure at SAIS Europe has given him
            the opportunity to focus on his research and earn his role as a
            celebrated scholar and historian. The research done for his PhD
            turned into his first book, America and the Reconstruction of Italy,
            1945-1948 (1986, Cambridge University Press), followed over the
            years by three other successful publications: American Visions of
            Europe: Franklin D. Roosevelt, George F. Kennan, and Dean G. Acheson
            (1994, Cambridge University Press), American Machiavelli: Alexander
            Hamilton and the Origins of U.S. Foreign Policy (2004, Cambridge
            University Press), and The Cold War (2011, Oxford University Press).
          </p>
          <p>
            In 2005, Harper became the first faculty and academic liaison for
            SAIS Europe, helping to manage the academic affairs of the school, a
            position he held on several occasions over the years. He is also the
            first Kenneth H. Keller Professor, created in 2014 to honor the
            former SAIS Europe director and current SAIS Europe Advisory Council
            member.
          </p>
          <p>
            Back in the early 1980s, when introducing Harper to the SAIS
            community, an article in the SAIS Europe publication Rivista
            observed, “For the time being, Harper has at least one more year in
            Bologna.” That one more year turned into four decades!{' '}
          </p>
          <p>
            To honor Harper’s legacy at the Bologna Center, contributions can be
            directed to the{' '}
            <Core.BoldText>
              “J. Harper and D. Calleo Fellowship Fund”
            </Core.BoldText>{' '}
            at SAIS Europe. Click{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://europe.jhu.edu/HarperCalleoFellowship/"
            >
              here
            </a>{' '}
            to make a gift or contact the SAIS Europe development team for more{' '}
            <a href="mailto:sais.eu.development@jhu.edu">information</a>.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>Faculty Honored</Core.Flag>

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Calder’s Latest Book Earns Distinction
          </Core.PrimaryHeading>
          <p>
            <Core.BoldText>Kent Calder</Core.BoldText>’s book{' '}
            <i>Super Continent: The Logic of Eurasian Integration</i>, has been
            selected as one of the best books of the year in the category of
            politics by Financial Times. Calder is vice dean for faculty affairs
            and international research cooperation and director of the Edwin O.
            Reischauer Center for East Asian Studies at SAIS.
          </p>
          <p>
            In the book, he examines the factors that have resulted in Europe
            and China growing closer, such as the global financial crisis of
            2008, China’s Belt and Road Initiative, technological improvements,
            and the political-economic transformation of Europe, Russia, and
            Southeast Asia.
          </p>
          <p>
            <i>Super Continent</i> is the sequel to Calder’s 2012 book,{' '}
            <i>
              The New Continentalism: Energy and Twenty-First-Century Eurasian
              Geopolitics
            </i>
            , which highlighted the transformation of Asia due to economic
            growth, rising energy demand, and the erosion of long-standing
            geopolitical divisions. Super Continent is now available in Japanese
            and will soon appear in Chinese, Korean, and Mongolian.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            A Catalyst to Examine Green Growth
          </Core.PrimaryHeading>
          <p>
            <Core.BoldText>Jonas Nahm</Core.BoldText>, assistant professor of
            Energy, Resources and Environment, is among 36 early-career faculty
            members at the Johns Hopkins University who were named 2020 Johns
            Hopkins Catalyst Awards recipients. The Catalyst Award is
            accompanied by a $75,000 grant for research, mentoring
            opportunities, and institutional recognition.{' '}
          </p>
          <p>
            Nahm will utilize the award grant to examine how clean energy
            industrial sectors in two countries with export-led economies, China
            and Germany, became part of critical political coalitions in support
            of decarbonization, and why green growth coalitions in the United
            Kingdom and United States’ consumption-led economies are less
            influential politically.
          </p>
          <p>
            Nahm says he is pleased and grateful that the grant will enable him
            to conduct two years of field research for this research project,
            adding “the main goal of the project is to better understand how
            countries have been able to mobilize broad political coalitions in
            support of climate policy.”
          </p>
          <p>
            Nahm joins six other Johns Hopkins SAIS faculty members who have
            been named Catalyst Awards recipients since the award was
            established in 2015.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Launchpad to a Healthier World
          </Core.PrimaryHeading>
          <p>
            <Core.BoldText>Jessica Fanzo</Core.BoldText>, Bloomberg
            Distinguished Professor of Global Food and Ethics, was recently
            awarded a $25,000 COVID-19 Launchpad Grant created by the Alliance
            for a Healthier World, Johns Hopkins’ signature initiative that
            integrates universitywide expertise and diverse perspectives to
            unlock groundbreaking knowledge in addressing unresolved global
            health equity challenges.
          </p>
          <p>
            To develop the winning proposal “Assessing Food Security Status
            Among Urban and Rural Vulnerable Groups in Sri Lanka During
            COVID-19,” Fanzo collaborated with Andrew Thorne-Lyman, associate
            scientist at the Johns Hopkins Bloomberg School of Public Health,
            and Quinn Marshall, a third-year doctoral student in the Department
            of International Health at the Bloomberg School.
          </p>
          <p>
            Fanzo will oversee a project examining how the Sri Lankan
            government’s COVID-19 policies will impact the accessibility of
            affordable, quality food for vulnerable groups within the country.
            Over the next six-plus months, Marshall will collect data in Sri
            Lanka and work with Fanzo and Thorne-Lyman to analyze and prepare it
            for publication in early 2021.
          </p>
          <p>
            Joining Fanzo as COVID-19 Launchpad Grant recipients are Johns
            Hopkins SAIS colleagues <Core.BoldText>Jonas Nahm</Core.BoldText>,
            assistant professor of Energy, Resources, and Environment;{' '}
            <Core.BoldText>Johannes Urpelainen</Core.BoldText>, director and
            Prince Sultan bin Abdulaziz Professor of Energy, Resources and
            Environment; and <Core.BoldText>Jeremy Shiffman</Core.BoldText>,
            Bloomberg Distinguished Professor of Global Health Policy.
          </p>
          <p>
            Nahm and Urpelainen collaborated with Scot Miller, assistant
            professor of Environmental Health and Engineering at the Johns
            Hopkins Whiting School of Engineering, to develop the winning grant
            proposal “Climate Impact Assessment of the Post-Pandemic Stimulus.”
            Over the next few months, they plan to track global stimulus
            spending to better understand how these funds are spent, assess how
            investments correlate with the avoided emissions, and create a
            website this summer that will compare each country’s stimulus
            packages.
          </p>
          <p>
            Shiffman collaborated with Yusra Shawar of the Bloomberg School of
            Public Health to develop the winning proposal “National
            Prioritization of Vulnerable Populations During the COVID-19
            Pandemic: A Multi-Country Policy Tracker.”
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Toward Energy-Efficient Footwear Production
          </Core.PrimaryHeading>
          <p>
            Assistant Professor of International Economics{' '}
            <Core.BoldText>Ritam Chaurey</Core.BoldText> was awarded the
            Entrepreneurship for Development internal grant worth $450,000
            funded by infoDev, a multitrust fund at the World Bank that supports
            entrepreneurs in developing economies.
          </p>
          <p>
            The grant will be used to fund a joint project with Yunfan Gu,
            Gaurav Nayyar, and Siddharth Sharma from the World Bank that will
            examine the determinants of — and barriers to — adoption of an
            energy-efficient technology by Bangladeshi leather goods and
            footwear producers. Chaurey will also collaborate with Eric
            Verhoogen from Columbia University to conduct research.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>A Top 10 Pick</Core.PrimaryHeading>
          <p>
            Senior Research Professor in International Relations{' '}
            <Core.BoldText>Daniel S. Markey</Core.BoldText>’s forthcoming book,
            China’s Western Horizon: Beijing and the New Geopolitics of Eurasia,
            has been included in British historian Peter Frankopan’s top 10 list
            of historical reads.
          </p>
          <p>
            China’s Western Horizon analyzes the evolving political, economic,
            and security links between China, Eurasia, and the Middle East.
            Within the book, Markey calls for the U.S. to act locally to compete
            with China globally.
          </p>
          <p>
            Markey’s first book, No Exit from Pakistan: America’s Tortured
            Relationship with Islamabad, examined the future of the
            U.S.-Pakistan relationship.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>In Memoriam</Core.Flag>

        <Core.ContentSection
          marginaliaStackedBottom
          marginalia={
            <>
              <Core.FullWidthImage
                image={sitIn}
                alt="SAIS students involved in a peaceful sit-in and hunger strike against the Vietnam War in March 1968"
                contentSectionPlacement="marginalia"
              />
              <Core.Caption>
                SAIS students involved in a peaceful sit-in and hunger strike
                against the Vietnam War in March 1968
              </Core.Caption>
              <br />
              <br />
              <Core.FullWidthImage
                image={leviNapolitano}
                alt="Arrigo Levi"
                contentSectionPlacement="marginalia"
              />
              <Core.Caption>
                As the councilor for external relations at the Quirinale, Arrigo
                Levi coordinated visits to SAIS Europe. Here above is Italian
                President Giorgio Napolitano with the SAIS Europe director at
                the time, Ken Keller, and Professors Pasquino and Harper.
              </Core.Caption>
            </>
          }
        >
          <Core.PrimaryHeading>Arrigo Levi</Core.PrimaryHeading>
          <p>
            Arrigo Levi, the Italian journalist, writer, and teacher, died in
            Rome on Aug. 24, 2020. He was 94. In addition to his outstanding
            career in politics and the media, he was also a cherished friend of
            SAIS Europe and a visiting faculty member during the 1967–68
            academic year.
          </p>
          <p>
            Born in Modena in 1926, Levi and his family, as Jews, escaped the
            persecution of the racial laws by immigrating to Argentina in 1942.
            There, he began working as a journalist and after the war returned
            to Modena, completed his studies, and pursued a lifetime career as a
            journalist. Levi served as the Russian correspondent for Corriere
            della Sera and subsequently for Il Giorno. In 1966, he moved to RAI
            (Radiotelevisione italiana). There, as the TV nightly news anchor,
            his face and voice became well-known across Italy. Returning to
            print journalism in 1968, Levi became a correspondent and then
            managing director of the Turin newspaper La Stampa. In 1988, he was
            appointed chief editor of Corriere della Sera.
          </p>
          <p>
            Levi came to SAIS Europe at a tumultuous time, as SAIS students
            (like university students throughout the U.S. and in Europe) engaged
            in peaceful protests and a hunger strike against the war in Vietnam.
            His years as a news correspondent in Moscow greatly influenced the
            international politics courses he taught, including Patterns of
            Crisis - Stalin’s Model.
          </p>
          <p>
            SAIS Europe Alumni <Core.BoldText>Robert Leonardi</Core.BoldText>{' '}
            (B’68, ’69) and his wife, Raffaella Nanetti (B’68, ’69), have fond
            memories of Arrigo in class and at the Bologna Center Café. The
            couple shared the following: “He was an educator who did not define
            himself as such (he did not like to be called Professor), but he was
            a great one. He was witty, insightful, brilliant, and caring.” In
            particular, Leonardi remembers one episode dating back to the spring
            of 1968. “It was after the students’ ‘hunger strike’ outside the
            center against the escalation in the Vietnam War. One morning when I
            had just come into the bar and was ordering my cappuccino, I heard
            somebody call, ‘Leonardi!’ I turned around and saw Levi sitting
            drinking his cappuccino. Levi simply said, ‘Leonardi, avevi ragione
            tu.’ Johnson had just announced that he was not running for
            reelection.”
          </p>
          <p>
            Among Levi’s numerous awards and honorary degrees was one in 2004
            from IULM University that reads: “Arrigo Levi has contributed to the
            best Italian journalism of investigation and civil spirit linked to
            major issues of international politics, and we also owe a very
            frequent activity of connection between the information system,
            political subjects at the highest levels of representativeness, and
            scholars made in qualified forums for debate and research aimed at
            improving the internationalization of our country. His writing has
            recently touched on important themes of reflection on the human
            condition: age, memory, and spiritual research.”{' '}
          </p>
          <p>
            Over the years, Levi maintained his relationship with SAIS and
            returned to the Bologna Center a number of times. In his
            long-standing role as the councilor for external relations at the
            Quirinale, first with Carlo Azeglio Ciampi and then with Giorgio
            Napolitano, he coordinated high-level visits at the Bologna Center.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>SAIS in the News</Core.Flag>

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              contentSectionPlacement="marginalia"
              image={conspiracy}
              alt="Conspiracy"
            />
          }
        >
          <Core.PrimaryHeading>
            The Danger of Conspiracy Theories
          </Core.PrimaryHeading>
          <p>
            In an interview with Business Insider (Aug. 23),{' '}
            <Core.BoldText>Anne Applebaum</Core.BoldText>, senior fellow of
            International Affairs, spoke about the rise of authoritarianism and
            the dangerous power of conspiracy theories, “which can be weaponized
            in order to undermine faith in democratic institutions, the media,
            and politicians.
          </p>
          <p>
            “I hate to say this — and I hope I&rsquo;m wrong — but I am afraid
            that if Trump loses in November, the groups who want civil war or
            race war will take to the streets,” said Applebaum, who is the
            author of several books about totalitarianism in Eastern Europe. She
            adds, “People who spend a lot of time on extremist websites have
            been told that Biden and Harris represent an evil, dangerous left,
            that they will destroy the country; some of them will want to take
            to the streets and prevent them from coming to power.”{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businessinsider.com/anne-applebaum-twilight-of-democracy-harpers-letter-authoritarianism-poland-hungary-2020-8?IR=T"
            >
              Read More
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              contentSectionPlacement="marginalia"
              image={exodus}
              alt="Exodus"
            />
          }
        >
          <Core.PrimaryHeading>Exodus Out of Hong Kong</Core.PrimaryHeading>
          <p>
            In the wake of a draconian national security law imposed by China
            following months of pro-democracy protests in Hong Kong, a growing
            number of residents are leaving to live and work in other countries,
            according to a Radio Free Asia report on Aug. 24.
          </p>
          <p>
            <Core.BoldText>Ho-Fung Hung</Core.BoldText>, the Henry M. and
            Elizabeth P. Wiesenfeld Professor in Political Economy, noted that
            the ruling Chinese Communist Party has likely already factored in
            the exodus into its plans.
          </p>
          <p>
            “The whole idea was that they wanted to keep Hong Kong but not its
            people. They can repopulate it because Hong Kong still has value for
            them as an offshore financial center,&rdquo; Hung is quoted as
            saying. &ldquo;But it won&rsquo;t do them much good because the rest
            of the world is already treating Hong Kong like the rest of China,
            legally speaking, so they are shooting themselves in the foot.”{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.rfa.org/english/news/china/crackdown-08242020141527.html"
            >
              Read More
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              contentSectionPlacement="marginalia"
              image={disparity}
              alt="Disparity"
            />
          }
        >
          <Core.PrimaryHeading>
            Disparity Breeds Resentment in Turkey
          </Core.PrimaryHeading>
          <p>
            Domestic support is fading for Turkish President Recep Tayyip
            Erdogan, according to an Aug. 26 article in Arab News, which notes
            that the self-styled strongman is losing support among young voters.
          </p>
          <p>
            <Core.BoldText>Lisel Hintz</Core.BoldText>, assistant professor of
            International Relations and European Studies, believes frustration
            is growing among voters over perceived corruption and waste during
            the years of AKP rule.
          </p>
          <p>
            “The massive disparity between a president who lives in a 1,000-plus
            room palace and the average citizen who has seen the price of
            produce quintuple breeds resentment. This is fueled by the view that
            Syrians offered temporary protection are a threat to citizens’
            economic and cultural status quo,” she is quoted as saying.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.arabnews.com/node/1724616/middle-east"
            >
              Read More
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              contentSectionPlacement="marginalia"
              image={debt}
              alt="Debt"
            />
          }
        >
          <Core.PrimaryHeading>
            Addressing Massive Debt, Post-COVID-19
          </Core.PrimaryHeading>
          <p>
            Writing in Project Syndicate,{' '}
            <Core.BoldText>Anne Krueger</Core.BoldText>, senior research
            professor of International Economics, notes that though massive
            government spending in response to the COVID-19 pandemic seems
            justified, U.S. policymakers will have to address the mounting
            public debt once the immediate crisis has passed.
          </p>
          <p>
            “The public should know that a dangerously high debt-to-GDP ratio
            will need to be addressed. … Otherwise, the nexus of high inflation
            and slow growth that accompanies financial repression could choke
            off the recovery,” writes Krueger.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.project-syndicate.org/commentary/financial-repression-for-us-debt-after-covid19-by-anne-krueger-2020-08"
            >
              Read More
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              contentSectionPlacement="marginalia"
              image={africa}
              alt="Africa"
            />
          }
        >
          <Core.PrimaryHeading>Instability in West Africa</Core.PrimaryHeading>
          <p>
            Instability has reined in Mali in recent years since Islamist rebels
            took advantage of existing disarray to gain control of the country’s
            north. Though the rebels lost control after French forces
            intervened, armed forces continue to terrorize civilians, and the
            violence has spread across the borders into Burkina Faso and Niger,
            according to an article in The New York Times, which reported on an
            Aug. 18 coup, staged after weeks of destabilizing protests.
          </p>
          <p>
            More than 10,000 West Africans have died, over 1 million have fled
            their homes, and military forces from West Africa and France have
            suffered many losses in recent years, the article reported.
          </p>
          <p>
            “That is the major concern here,” noted{' '}
            <Core.BoldText>Chiedo Nwankwor</Core.BoldText>, lecturer and
            director of SAIS Women Lead, in the article. “These various jihadist
            movements in Africa do not bode well for any Western government.”{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.nytimes.com/2020/08/19/world/africa/Whats-happening-Mali-coup.html"
            >
              Read More
            </a>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>Sphere of Influence</Core.Flag>

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Minimizing Uncertainty in the Age of COVID-19
          </Core.PrimaryHeading>
          <p>
            <Core.BoldText>Marta Giagheddu</Core.BoldText>, who teaches
            macroeconomics at SAIS, is passionate about using the tools of her
            discipline to do good in the world.
          </p>
          <p>
            So when the Italian native — who earned bachelor’s and master’s
            degrees in economics at Bocconi University in Milan, and then a PhD
            at the Stockholm School of Economics in Sweden — started looking for
            an academic job, SAIS seemed the perfect fit.
          </p>
          <p>
            “Because I am interested in public policy, it is a great privilege
            to teach here,” says Giagheddu, who joined the SAIS faculty as
            assistant professor of International Economics in fall 2018. “I am
            constantly amazed by my students and their huge awareness of the
            world and its challenges.”
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage image={marta} alt="Marta Giagheddu" />

        <Core.ContentSection
          marginalia={
            <Core.Blockquote>
              I am constantly amazed by my students and their huge awareness of
              the world and its challenges.
            </Core.Blockquote>
          }
        >
          <p>
            One of those challenges hit SAIS in the middle of the spring
            semester, when Giagheddu was teaching her class for the third time.
          </p>
          <p>
            “COVID-19 forced us online overnight,” she says, “and it was a shock
            for everyone, but our focus as a faculty was to minimize uncertainty
            for our students, for whom this has been incredibly stressful.”
            Giagheddu used tools that allowed her to demonstrate equations on a
            virtual whiteboard, launched an online discussion forum, and started
            doing online live sessions as well as bilateral meetings with
            students, which she conducts on Zoom during regular office hours.
          </p>
          <p>
            “Online classes offer students more flexibility,” she says, “but I
            do miss reading their faces and better understanding whether I need
            to repeat or explain something in a different way.”{' '}
          </p>
          <p>
            Because SAIS students are eager to tackle policy issues, they can be
            understandably frustrated that learning macroeconomic tools is, at
            least in the beginning, necessarily abstract, Giagheddu says. “But
            by the end of the course, they are ready to take this powerful tool
            set and apply it to real-world circumstances, and that, for me, is
            deeply gratifying.” Joan Katherine Cramer
          </p>
        </Core.ContentSection>

        <Core.MoreResources>
          To stay up to date on SAIS experts in the news, visit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.sais-jhu.edu/opt-in"
          >
            sais-jhu.edu/opt-in
          </a>{' '}
          and select the option for <strong>The Brief</strong>, which is
          produced monthly by the Office of Marketing, Communications, and
          Strategic Initiatives.
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    deanCrowell: file(
      relativePath: { regex: $directory }
      name: { eq: "Dean-Crowell" }
    ) {
      ...ImagePragraphWrapImage
    }
    andrewMertha: file(
      relativePath: { regex: $directory }
      name: { eq: "Andrew-Mertha" }
    ) {
      ...ContentWidthImage
    }
    andreaPresbitero: file(
      relativePath: { regex: $directory }
      name: { eq: "Andrea-Presbitero" }
    ) {
      ...ContentWidthImage
    }
    filippoTaddei: file(
      relativePath: { regex: $directory }
      name: { eq: "Filippo-Taddei" }
    ) {
      ...ContentWidthImage
    }
    johnHarper: file(
      relativePath: { regex: $directory }
      name: { eq: "John-Harper-color" }
    ) {
      ...ContentWidthImage
    }
    johnHarperMargin: file(
      relativePath: { regex: $directory }
      name: { eq: "John-Harper-margin" }
    ) {
      ...MarginaliaImage
    }
    sitIn: file(
      relativePath: { regex: $directory }
      name: { eq: "Levi-Student-Sit-In-margin" }
    ) {
      ...MarginaliaImage
    }
    leviNapolitano: file(
      relativePath: { regex: $directory }
      name: { eq: "Levi-Napolitano-margin" }
    ) {
      ...MarginaliaImage
    }
    conspiracy: file(
      relativePath: { regex: $directory }
      name: { eq: "conspiracy" }
    ) {
      ...MarginaliaImage
    }
    exodus: file(relativePath: { regex: $directory }, name: { eq: "exodus" }) {
      ...MarginaliaImage
    }
    disparity: file(
      relativePath: { regex: $directory }
      name: { eq: "disparity" }
    ) {
      ...MarginaliaImage
    }
    debt: file(relativePath: { regex: $directory }, name: { eq: "debt" }) {
      ...MarginaliaImage
    }
    africa: file(relativePath: { regex: $directory }, name: { eq: "africa" }) {
      ...MarginaliaImage
    }
    marta: file(
      relativePath: { regex: $directory }
      name: { eq: "Marta-Giagheddu" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
